import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import mask from '../../image/footer-logo-2 2.svg'
import './ProfileCard-unique.css';
import like from '../../image/like_share.png'
import no_profile from '../../image/no_profile.jpeg'
import axios from 'axios';

const ImageSharePage = () => {
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
    const [imageData, setImageData] = useState([]);
    const [youtubeData, setYoutubeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imagePath, setImagePath] = useState('');
    const [name, setName] = useState('');
    const userName = localStorage.getItem('name');

    // Function to extract image path from URL
    // const getImagePathFromUrl = () => {
    //     const params = new URLSearchParams(location.search);
    //     setImagePath(params.get('imagePath'));
    //     return params.get('imagePath');
    // };
    // const getNameFromUrl = () => {
    //     const params = new URLSearchParams(location.search);
    //     setName(params.get('name'));
    //     return params.get('name');
    // };

    // const x1=getImagePathFromUrl()
    // const x2=getNameFromUrl()
    const extractParamsFromUrl = () => {
        const params = new URLSearchParams(window.location.search);
        const imagePathFromUrl = params.get('imagePath');
        const nameFromUrl = params.get('name');
        setImagePath(imagePathFromUrl);
        setName(nameFromUrl);
    };

    useEffect(() => {
        extractParamsFromUrl();
    }, [window.location.search]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Send POST request to fetch image data
                const imageResponse = await axios.post(' https://backend.clicktalksnow.com/get_post/images', {
                    path: imagePath,
                    name: name
                });

                
                const imagesPath = imageResponse.data?.image_paths;
               
                // Check if images_path exists and is an array before accessing [0]
                if (Array.isArray(imagesPath) && imagesPath.length > 0) {
                    setImageData(imagesPath[0]);
                    
                   
                } else {
                    // If images_path is not found or is empty, proceed to fetch YouTube data
                    const youtubeResponse = await axios.post(' https://backend.clicktalksnow.com/get_post/video', {
                        path: imagePath,  // Sending path along with name
                        name: name
                    });
                    
                    const videoPaths = youtubeResponse.data?.video_paths;
                   
                    // Check if video_paths exists and is an array before accessing [0]
                    if (Array.isArray(videoPaths) && videoPaths.length > 0) {
                      
                        setYoutubeData(videoPaths[0]);
                    } else {
                        console.error('No video paths found.');
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData()
    }, [imagePath, name]);

    useEffect(() => {
        // Disable right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
    
        // Disable common keyboard shortcuts for opening developer tools
        const handleKeyDown = (e) => {
          if (
            e.key === 'F12' ||
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
            (e.ctrlKey && e.key === 'U')
          ) {
            e.preventDefault();
          }
        };
    
        // Detect if developer tools are open and take action
        const handleDevToolsOpen = () => {
          const element = new Image();
          Object.defineProperty(element, 'id', {
            get: function () {
              alert('Developer tools are open!');
              window.location.replace('about:blank'); // Redirect to a blank page
            },
          });
          console.log(element);
        };
    
        // Add event listeners
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        handleDevToolsOpen();
    
        // Cleanup event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
   

    const toggleNavbar = () => {
        setMobileNavbarOpen(!isMobileNavbarOpen);
        if (!isMobileNavbarOpen) {
            setMobileSidebarOpen(false); // Close sidebar if navbar is opened
        }
    };


    const handleSignOut = () => {
        localStorage.clear();
    };
    



    return (
        <div>
            <div className="navbar" style={{ zIndex: '100', fontSize: '18px' }}>
                <div className="navbar-logo1">
                    <img src={mask}></img>
                    <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>
                <div className="navbar-links">
                    {isAuthenticated && (
                        <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
                    )}

                    <Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
                    <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
                    <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

                    {isAuthenticated && userName ? (
                        <>
                            <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                            <Link to="/" onClick={handleSignOut}>Sign Out</Link>
                        </>
                    ) : (
                        <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
                    )}



                </div>
                <div className="navbar-menu" onClick={toggleNavbar}>
                    &#9776; {/* Three dots icon for navbar */}
                </div>
                {isMobileNavbarOpen && (
                    <div className="mobile-navbar-links">
                        {isAuthenticated && (
                            <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
                        )}

                        <Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
                        <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
                        <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

                        {isAuthenticated && userName ? (
                            <>
                                <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                                <Link to="/" onClick={handleSignOut}>Sign Out</Link>
                            </>
                        ) : (
                            <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
                        )}

                    </div>
                )}
            </div>
            <div className="profile-card-container-unique">
                <div className="profile-card-unique">
                    <div className="profile-header-unique">
                        <div className="profile-pic-unique"><img src={no_profile}></img></div>
                        <div className="profile-name-unique">{name}</div>
                    </div>
                    {imageData && imageData.image_paths ? (
            <img
                src={imageData.image_paths}
                alt="No media found"
                className="profile-image-unique"
            />
        ) : youtubeData && youtubeData.video_path ? (
            <iframe
                loading='lazy'
                width="100%"
                height="200"
                src={youtubeData.video_path}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={youtubeData.name}
            ></iframe>
                    ) : (
                        <p>.....Loading</p>
                    )}
                    <div className="profile-footer-unique">
                        <div className="like-section-unique">
                            <img src={like}></img>
                            <span> {imageData && imageData.image_path ? imageData.likes : youtubeData.likes || 0}</span>
                        </div>
                        <button className="profile-button-unique"><Link to = {`/userprofile?name=${name}` } style={{textDecoration:'none',color:'white'}}>Go to {name} profile</Link></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageSharePage;
