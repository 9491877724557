import React, { useState, useEffect } from 'react';
import '../contest.css';
import profile_logo from '../../../image/Vector.png'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp ,faThumbsDown } from '@fortawesome/free-solid-svg-icons';

const Contest_page_2 = () => {

    const [showPopup2, setShowPopup2] = useState(false);
    const [videos, setVideos] = useState([])
    const [teamName, setTeamName] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [college, setcollege] = useState('');
    const [Description, setDescription] = useState('');

    const [pagination, setPagination] = useState({
      total: 0,
      page: 1,
      page_size: 9,
      total_pages: 0
    });

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async (page = 1) => {
      try {
        const response = await fetch(
          `https://backend.clicktalksnow.com/shortlisted_get_fashion_voting_videos/?page=${page}&page_size=9`
        );
        
        const data = await response.json();
        setVideos(data.result);
        setPagination(data.pagination);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    const handlePageChange = (pageNumber) => {
      window.scrollTo(0, 0);
      fetchVideos(pageNumber);
    };
    
    // Pagination component with truncation logic
    const Pagination = () => {
      const { page, total_pages } = pagination;
      
      // Helper function to create page numbers array with proper truncation
      const getPageNumbers = () => {
        let pages = [];
        
        // Always show first page
        pages.push(1);
        
        if (page > 3) {
          // Add truncation after first page if current page is far enough
          pages.push('...');
        }
        
        // Calculate range of pages to show around current page
        const rangeStart = Math.max(2, page - 1);
        const rangeEnd = Math.min(total_pages - 1, page + 1);
        
        // Add pages in range
        for (let i = rangeStart; i <= rangeEnd; i++) {
          if (i !== 1 && i !== total_pages) {
              pages.push(i);
          }
        }
        
        if (page < total_pages - 2) {
          // Add truncation before last page if current page is far enough
          pages.push('...');
        }
        
        // Always show last page if there's more than one page
        if (total_pages > 1) {
          pages.push(total_pages);
        }
        
        return pages;
      };
      
      // Don't render pagination if there's only one page
      if (total_pages <= 1) return null;
      
      const pageNumbers = getPageNumbers();
      
      return (
        <div className="pagination-container">
          <div className="pagination">
              <button 
              className="pagination-button" 
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              >
              Back
              </button>
              
              {pageNumbers.map((pageNumber, index) => (
              pageNumber === '...' ? (
                  <span key={`ellipsis-${index}`} className="pagination-ellipsis">
                  ...
                  </span>
              ) : (
                  <button
                  key={pageNumber}
                  className={`pagination-button ${page === pageNumber ? 'active' : ''}`}
                  onClick={() => handlePageChange(pageNumber)}
                  >
                  {pageNumber}
                  </button>
              )
              ))}
              
              <button 
              className="pagination-button" 
              onClick={() => handlePageChange(page + 1)}
              disabled={page === total_pages}
              >
              Next
              </button>
          </div>
        </div>
      );
    };

    const addVideo = async () => {
        
        try {
          
          const response = await fetch('  https://backend.clicktalksnow.com/shortlisted_upload_fashion_voting_videos/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name:teamName, youtube_path: youtubeLink , clg:college, description:Description}),
          });
          if (response.ok) {
            setTeamName('');
            setYoutubeLink('');
            setDescription('')
            setcollege('')
            alert('Photo uploaded successfully!');
            fetchVideos(); 
            handleClosePopup2()
          } else {
            console.error('Error adding photo');
          }
        } catch (error) {
          alert('Error adding photo:', error);
        }
      };


      const handleDelete = async (id) => {
        try {
          const response = await fetch(`  https://backend.clicktalksnow.com/shortlisted_delete_fashion_voting_video/${id}/`, {
            method: 'DELETE',
          });
    
          if (response.ok) {
            alert('Photo deleted successfully!');
            fetchVideos(); // Refresh the video list after deletion
          } else {
            console.error('Error deleting photo');
            alert('Error deleting photo. Please try again.');
          }
        } catch (error) {
          console.error('Error deleting photo:', error);
          alert('Error deleting photo. Please try again.');
        }
      };

    const handleParticipateClick1 = () => {
        setShowPopup2(true);
    };

    const handleClosePopup2 = () => {
        setShowPopup2(false);
    };


    const handleSubmit1 = async (e) => {
        e.preventDefault();
        addVideo()
    };

    return (
        <div>
           
                <div className='content-participate'>
                    <h2>Admin</h2>
                        <button className="participate-button" onClick={handleParticipateClick1}>Upload</button>
                </div>
                <div className="gallery">
                    { videos!=undefined && videos.map(video => (
                        <div className="card" key={video.id}>

                            <div style={{ textAlign: 'left' }} className="video-title">
                                <div className='vedio_controls'><span><img src={profile_logo}></img></span>
                                <p>{video.name}</p></div>
                                <div><button onClick={() => handleDelete(video.id)} className="delete-button" >Delete</button></div>
                            </div>
                            <iframe 
                                loading='lazy'
                                width="100%"
                                height="200"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title={video.name}
                                src={video.youtube_path.replace(/(\/file\/d\/[^/]+)\/.*/, "$1/preview")}>
                            </iframe>

                            <div className="card-content">

                                <div className="likes">
                                    <div className="heart"><FontAwesomeIcon icon={faThumbsUp}/></div>
                                    <div>{video.likes}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Pagination />
            
            {showPopup2 && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={handleClosePopup2}>&times;</span>
                        <h2>Admin Access</h2>
                        <form onSubmit={handleSubmit1}>
                            <div className="form-group">
                                <label htmlFor="Youtube-link">Google_Drive_link:</label>
                                <input type="url" id="youtube-link" 
                                onChange={(e) => setYoutubeLink(e.target.value)}
                                name="youtubelink" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="team-name">Team Name:</label>
                                <input type="text" id="team-name" name="teamName"
                                onChange={(e) => setTeamName(e.target.value)}
                                 required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Description">Description:</label>
                                <input type="text" id="Description" name="Description"
                                onChange={(e) => setDescription(e.target.value)}
                                 required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="college">College:</label>
                                <input type="text" id="college" name="college"
                                onChange={(e) => setcollege(e.target.value)}
                                 required />
                            </div>
                            
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Contest_page_2