import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    WhatsappIcon,
    TwitterIcon,
    LinkedinIcon,
} from 'react-share';
import { FaShareAlt } from 'react-icons/fa';
import './ShareButton.css';
import like_share from '../../image/like_share.png'

const ShareButton = ({ url, title }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    useEffect(() => {
        // Disable right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
    
        // Disable common keyboard shortcuts for opening developer tools
        const handleKeyDown = (e) => {
          if (
            e.key === 'F12' ||
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
            (e.ctrlKey && e.key === 'U')
          ) {
            e.preventDefault();
          }
        };
    
        // Detect if developer tools are open and take action
        const handleDevToolsOpen = () => {
          const element = new Image();
          Object.defineProperty(element, 'id', {
            get: function () {
              alert('Developer tools are open!');
              window.location.replace('about:blank'); // Redirect to a blank page
            },
          });
          console.log(element);
        };
    
        // Add event listeners
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        handleDevToolsOpen();
    
        // Cleanup event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    // Construct the share URL based on the title
    const baseUrl = 'https://www.clicktalksnow.com/share'; // Change this to your actual base URL
    const shareUrl = title === 'contest' ? url : `${baseUrl}?imagePath=${encodeURIComponent(url)}&name=${encodeURIComponent(title)}`;

    return (
        <div className='share_button'>
            <div className="share-button-wrapper">
                {/* <button className="main-share-button" onClick={openModal}>
                    <FaShareAlt size={24} />
                    Share
                </button> */}
                <img src={like_share} className="image_setting" onClick={openModal} ></img>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="share-modal"
                    overlayClassName="share-modal-overlay"
                    contentLabel="Share Modal"
                >
                    <h2 className="share-modal-title">Share this Image</h2>
                    <div className="share-options">
                        <FacebookShareButton url={shareUrl} className="share-button">
                            <FacebookIcon size={40} round />
                        </FacebookShareButton>

                        <WhatsappShareButton url={shareUrl} className="share-button">
                            <WhatsappIcon size={40} round />
                        </WhatsappShareButton>

                        <TwitterShareButton url={shareUrl} className="share-button">
                            <TwitterIcon size={40} round />
                        </TwitterShareButton>

                        <LinkedinShareButton url={shareUrl} className="share-button">
                            <LinkedinIcon size={40} round />
                        </LinkedinShareButton>
                    </div>
                    <button className="close-modal-button" onClick={closeModal}>
                        Close
                    </button>
                </Modal>
            </div>
        </div>
    );
};

export default ShareButton;
