import React, { useState, useEffect } from 'react';
import './contest.css';
import profile_logo from '../../image/Vector.png'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import like_share from '../../image/like_share.png'
import VideoPopup from './VideoPopup';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ShareButton from '../share_button/share_button';





const Contest_page_1 = () => {
    const [videos, setVideos] = useState([])
    const [showPopup1, setShowPopup1] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [serverOtp, setServerOtp] = useState("");
    const [loading,setLoading] =  useState(false);


    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        page_size: 9,
        total_pages: 0
    });

    const [selectedVideo1, setSelectedVideo1] = useState(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const videoId = params.get('id');
        if (videoId) {
            const fetchVideo = async () => {
                try {
                    const response = await axios.get(`  https://backend.clicktalksnow.com/get_short_film_video_by_id/${videoId}`);
                    // Replace with your API URL
                    setSelectedVideo1(response.data.result[0]);

                } catch (error) {
                    console.error('Error fetching video details:', error);
                }
            };

            fetchVideo();
        }
        else {
            // If no videoId, clear selectedVideo1
            setSelectedVideo1(null);
        }

        // Update the current URL state
        setCurrentUrl(window.location.href);
    }, [window.location.search]);

    const handleReadMoreClick = (video) => {
        setSelectedVideo1(video);
        const params = new URLSearchParams(window.location.search);
        const updatedUrl = `${window.location.pathname}?${params.toString()}`;
        params.set('id', video.id); // Assuming video.id is the unique identifier
        window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
        setCurrentUrl(updatedUrl);
    };

    const handleClosePopup1 = () => {
        setSelectedVideo1(null);
        const params = new URLSearchParams(window.location.search);
        params.delete('id');
        const updatedUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
        setCurrentUrl(updatedUrl);
    };

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async (page = 1) => {
        try {
          setLoading(true);
          const response = await fetch(
            `https://backend.clicktalksnow.com/get_voting_videos/?year=2023&page=${page}&page_size=9`
          );
          
          const data = await response.json();
          setVideos(data.result);
          setPagination(data.pagination);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching videos:', error);
          setLoading(false);
        }
    };
    const [formData, setFormData] = useState({
        driveLink: '',
        teamName: '',
        clgName: '',
        description: '',
        category: '',
        teamEmail: '',
        phoneNumber: ''
    });

    const handlePageChange = (pageNumber) => {
        window.scrollTo(0, 0);
        fetchVideos(pageNumber);
    };
    
    // Pagination component with truncation logic
    const Pagination = () => {
    const { page, total_pages } = pagination;
    
    // Helper function to create page numbers array with proper truncation
    const getPageNumbers = () => {
        let pages = [];
        
        // Always show first page
        pages.push(1);
        
        if (page > 3) {
        // Add truncation after first page if current page is far enough
        pages.push('...');
        }
        
        // Calculate range of pages to show around current page
        const rangeStart = Math.max(2, page - 1);
        const rangeEnd = Math.min(total_pages - 1, page + 1);
        
        // Add pages in range
        for (let i = rangeStart; i <= rangeEnd; i++) {
        if (i !== 1 && i !== total_pages) {
            pages.push(i);
        }
        }
        
        if (page < total_pages - 2) {
        // Add truncation before last page if current page is far enough
        pages.push('...');
        }
        
        // Always show last page if there's more than one page
        if (total_pages > 1) {
        pages.push(total_pages);
        }
        
        return pages;
    };
    
    // Don't render pagination if there's only one page
    if (total_pages <= 1) return null;
    
    const pageNumbers = getPageNumbers();
    
    return (
        <div className="pagination-container">
        <div className="pagination">
            <button 
            className="pagination-button" 
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            >
            Back
            </button>
            
            {pageNumbers.map((pageNumber, index) => (
            pageNumber === '...' ? (
                <span key={`ellipsis-${index}`} className="pagination-ellipsis">
                ...
                </span>
            ) : (
                <button
                key={pageNumber}
                className={`pagination-button ${page === pageNumber ? 'active' : ''}`}
                onClick={() => handlePageChange(pageNumber)}
                >
                {pageNumber}
                </button>
            )
            ))}
            
            <button 
            className="pagination-button" 
            onClick={() => handlePageChange(page + 1)}
            disabled={page === total_pages}
            >
            Next
            </button>
        </div>
        </div>
    );
    };

    const handleVoteNowClick = (video) => {
        setSelectedVideo(video);
        setShowPopup1(true);
        setOtpSent(false); // Reset OTP state
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    const handleSendOtp = async () => {

        try {
            setLoading(true);
            const response = await axios.post('  https://backend.clicktalksnow.com/send-otp/', { email });
            if (response.status === 200) {
                setOtpSent(true);
                setServerOtp(response.data.otp);
            }
        } catch (error) {
            alert("Error sending OTP. Please try again.");
        }finally {
            setLoading(false); // End loading
        }

    };

    const updateLikes = async (id) => {
        try {
            const response = await fetch(`  https://backend.clicktalksnow.com/voting_videos/likes/${id}`, {
                method: 'PUT'
            });
            if (response.ok) {
                alert('OTP verified successfully. Vote counted!');
                handleClosePopup_vote();
                fetchVideos()
            }
            else {
                alert("Error verifying vote. Please try after sometime.")
            }
        } catch (error) {
            alert("Error verifying vote. Please try again.");
        }
    }


    const handleVerifyOtp = async () => {

        if (otp === serverOtp) {
            setLoading(true); 
            try {
                const verifyResponse = await fetch('  https://backend.clicktalksnow.com/verifyvotings/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: email, unique_id: selectedVideo.unique_id, name:name, phone:phone }),
                });
                const data = await verifyResponse.json();
                if (data.message) {
                    alert(data.message);
                    handleClosePopup_vote();
                } else {
                    updateLikes(selectedVideo.id);
                }
            } catch (error) {
                alert('Error verifying vote:', error);
            }
            finally {
                setLoading(false); // End loading
            }

        } else {
            alert('Invalid OTP. Please try again.');
        }
    };

    const handleClosePopup_vote = () => {
        setShowPopup1(false);
        setSelectedVideo(null);
        setName('');
        setEmail('');
        setPhone('');
        setOtp('');
    };


    return (
        <div>
            <div className='content-participate'>
                <h2>Creative Carnival Talent Hunt 2023</h2>
            </div>
            <div className="gallery">
                {videos != undefined && videos.map(video => (
                    <div className="card" key={video.id}>

                        <div style={{ textAlign: 'left' }} className="video-title">

                            <div className='vedio_controls'><span>
                                <img src={profile_logo}></img></span>
                                <div>
                                    <p style={{ marginTop: '10px', marginBottom: '1px', }}>{video.name}</p>
                                    <span style={{ color: '#888888', marginTop: '2px', fontSize: '14px' }}>{video.clg}</span>
                                </div>

                            </div>

                        </div>
                        <iframe
                            loading='lazy'
                            width="100%"
                            height="200"
                            src={`${video.youtube_path}`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={video.name}
                        ></iframe>
                        <div className="card-content">

                            <div className="likes">
                                <div className='sharing'>
                                    <div className="heart"><FontAwesomeIcon icon={faThumbsUp} /></div>
                                    <div>{video.likes}</div>
                                </div>
                                {/* <div><img src={like_share}></img></div> */}

                                <div style={{ cursor: 'pointer' }}><ShareButton url={`${currentUrl}&id=${video.id}`} title={'contest'} /></div>
                            </div>
                        </div>
                        <div className='line1'></div>
                        <div className='descrpt'>
                            <h3>The Explorer</h3>
                            <span>{video.descrpt.split(" ").slice(0, 20).join(" ") + (video.descrpt.split(" ").length > 20 ? "... " : " ")}</span>
                            <span className='read_me_more' onClick={() => handleReadMoreClick(video)}>Read more </span>
                        </div>
                        <div className='voting'><button className="vote-now-button" onClick={() => handleVoteNowClick(video, video.id)}>Vote Now</button></div>
                    </div>
                ))}
                {selectedVideo1 && <VideoPopup video={selectedVideo1} onClose={handleClosePopup1} />}
            </div>
            <Pagination />

            {showPopup1 && selectedVideo && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={handleClosePopup_vote}>&times;</span>
                        <h2>Vote for {selectedVideo.name}</h2>

                        <form>
                            <div className="form-group">

                                <label htmlFor="name">Enter name:</label>
                                <input type="text" id="name" value={name} onChange={handleNameChange} required
                                  disabled={loading} />

                                <label htmlFor="email">Enter email:</label>
                                <input type="email" id="email" value={email} onChange={handleEmailChange} required
                                  disabled={loading} />

                                <label htmlFor="phone">Enter phone:</label>
                                <input type="tel" id="phone" value={phone} onChange={handlePhoneChange} required
                                  disabled={loading} />

                            </div>

                            {!otpSent ? (
                                <button type="button" onClick={handleSendOtp} disabled={loading}>
                                {loading ? 'Sending OTP...' : 'Send OTP'}
                            </button>
                            ) : (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="otp">Enter OTP:</label>
                                        <input type="text" id="otp" value={otp} onChange={handleOtpChange} required 
                                         disabled={loading}/>
                                    </div>
                                    <button type="button" onClick={handleVerifyOtp} disabled={loading}>
                            {loading ? 'Verifying OTP...' : 'Verify OTP'}
                        </button>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Contest_page_1