import React, { useEffect, useRef } from 'react';
import './MarqueeCarousel.css';
import carouselImg1 from './image copy/carouselImg1.jpg';
import carouselImg2 from './image copy/carouselImg2.jpg';
import carouselImg3 from './image copy/carouselImg3.jpg';
import carouselImg4 from './image copy/carouselImg4.jpg';

const MarqueeCarousel = () => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marquee = marqueeRef.current;

    const scrollMarquee = () => {
      if (marquee.scrollLeft >= marquee.scrollWidth / 2) {
        marquee.scrollLeft = 0;
      } else {
        marquee.scrollLeft += 1;
      }
    };

    const intervalId = setInterval(scrollMarquee, 10);

    return () => clearInterval(intervalId);
  }, []);

  const images = [carouselImg1, carouselImg2, carouselImg3, carouselImg4];

  return (
    <div className="marquee-container" ref={marqueeRef}>
      <div className="marquee">
        {images.map((img, index) => (
          <img key={index} src={img} alt={`Carousel Image ${index + 1}`} />
        ))}
        {images.map((img, index) => (
          <img key={index + images.length} src={img} alt={`Carousel Image ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default MarqueeCarousel;
