// ContestPage.js
import React from 'react';
import styles from './ContestPage.module.css';
import contest from './image/contest.jpeg';
import { Link } from 'react-router-dom';

const ContestPage = () => {
    return (
      <div className={styles.container}>
        <main className={styles.mainContent}>
          <section className={styles.contentSection}>
            <div className={styles.heroSection}>
              <div className={styles.imageContainer}>
                <img src={contest} alt="Contest" className={styles.contestImage}  />
              </div>
              <div className={styles.overlayText}>
                <h1>Present &amp; Upcoming Contest</h1>
                <p>
                  Present and Upcoming events, Students and anyone can register now and participate in our contest !!!
                </p>
              </div>
              <button  className={styles.registerButton}><Link to='/contest' style={{textDecoration:'none',color:'black'}}>Register Now</Link></button>
            </div>
            <div className={styles.calendarSection}>
              <h2>Calendar</h2>
              <ul className={styles.contestsList}>
                <li className={styles.contestItem}>
                  <div className={styles.date}>30 June</div>
                  <div className={styles.description}>Best Photographer with Cinematic shots Students and individuals ....</div>
                  <a href="#" className={styles.knowMore}>Know more</a>
                </li>
                <li className={styles.contestItem}>
                  <div className={styles.date}>05 July</div>
                  <div className={styles.description}>Best Short film with Cinematic shots Students and individuals ....</div>
                  <a href="#" className={styles.knowMore}>Know more</a>
                </li>
                <li className={styles.contestItem}>
                  <div className={styles.date}>14 Aug</div>
                  <div className={styles.description}>Best Short film with Cinematic shots Students and individuals ....</div>
                  <a href="#" className={styles.knowMore}>Know more</a>
                </li>
              </ul>
            </div>
          </section>
        </main>
      </div>
    );
  };
  

export default ContestPage;
