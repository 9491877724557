// ForgotPasswordModal.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './forgot.css'; // Create a separate CSS file for modal styles

const ForgotPasswordModal = ({ isOpen, onClose }) => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [sentOtp, setSentOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpError, setOtpError] = useState('');

    const [sendOtpLoading, setSendOtpLoading] = useState(false);
    const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        try {
            setSendOtpLoading(true);
            const response = await axios.post('  https://backend.clicktalksnow.com/send-otp/', { email: email });
            setSentOtp(response.data.otp);
            setOtpSent(true);
            
        } catch (error) {
            console.error('Error sending OTP', error);
        }
        finally {
            setSendOtpLoading(false); // Stop loading after response
        }
        setStep(2);
    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        try {
            setVerifyOtpLoading(true); // Start loading for verifying OTP
            if (otp === sentOtp) {
                setOtpVerified(true);
                setOtpError('');
            } else {
                setOtpError('Invalid OTP');
                alert('Invalid OTP');
            }
        } catch (error) {
            console.error('Error verifying OTP', error);
        } finally {
            setVerifyOtpLoading(false); // Stop loading after response
        }
        setStep(3);
    };

   
    const handlePasswordSubmit = async (event) => {
       
        event.preventDefault();
        
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        try {
            setResetPasswordLoading(true);
            const response= await axios.put('  https://backend.clicktalksnow.com/password_change', { email, password: newPassword });
            alert(response.data.message)
            onClose();
        } catch (error) {
            console.error('Error resetting password', error);
            alert('Failed to reset password. Please try again.');
        }finally {
            setResetPasswordLoading(false); // Stop loading after response
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className='forgot'>
        <div className="modal-overlay">
            <div className="modal-content">
                <span><button className="close-button" onClick={onClose}>X</button></span>
                {step === 1 && (
                    <form onSubmit={handleEmailSubmit}>
                        <h2>Forgot Password</h2>
                        <label>
                            Email address*:
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                disabled={sendOtpLoading}
                            />
                        </label>
                        <button type="submit" disabled={sendOtpLoading}>
                                {sendOtpLoading ? 'Sending OTP...' : 'Send OTP'}
                            </button>
                    </form>
                )}
                {otpSent && !otpVerified && (
                    <form onSubmit={handleOtpSubmit}>
                        <h2>Enter OTP</h2>
                        <label>
                            OTP*:
                            <input
                                type="text"
                                name="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                disabled={verifyOtpLoading}
                            />
                        </label>
                        <button type="submit" disabled={verifyOtpLoading}>
                                {verifyOtpLoading ? 'Verifying...' : 'Verify OTP'}
                            </button>
                    </form>
                )}
                {otpVerified && (
                    <form onSubmit={handlePasswordSubmit}>
                        <h2>Reset Password</h2>
                        <label>
                            New Password*:
                            <input
                                type={passwordShown ? "text" : "password"}
                                name="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                disabled={resetPasswordLoading}
                            />
                            <FontAwesomeIcon
                                icon={passwordShown ? faEye : faEyeSlash}
                                onClick={togglePasswordVisibility}
                                style={{ cursor: 'pointer' }}
                            />
                        </label>
                        <label>
                            Confirm Password*:
                            <input
                                type={passwordShown ? "text" : "password"}
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                disabled={resetPasswordLoading}
                            />
                        </label>
                        <button type="submit" disabled={resetPasswordLoading}>
                                {resetPasswordLoading ? 'Resetting Password...' : 'Reset Password'}
                            </button>
                    </form>
                )}
            </div>
        </div>
        </div>
    );
};

export default ForgotPasswordModal;
