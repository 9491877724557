import React, { useState, useEffect } from 'react';

import MarqueeCarousel from './marqs.js';

import './about2.css';
import img1 from './image/img1.jpg';
import img2 from './image/img2.jpg';
import img3 from './image/img3.jpg';
import img4 from './image/img4.jpg';
import img11 from './image2/img1.jpg';
import img12 from './image2/img2.jpg';
import img13 from './image2/img3.jpg';

import blogImg1 from './image/blogImg1.jpg.jpg'
import blogImg2 from './image/blogImg2.jpg.jpg'
import blogImg3 from './image/blogImg3.jpg.jpg'
import ContestPage from './ContestPage';
import celebrity1 from './image/celebrity1.jpg.jpg';
import celebrity2 from './image/celebrity2.jpg.jpg';
import celebrity3 from './image/celebrity3.jpg.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import mask from '../../image/footer-logo-2 2.svg'
import { faInstagram, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';


import { text } from '@fortawesome/fontawesome-svg-core';

const slideImages = [img11, img12, img13];

const About = () => {
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 568);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 568);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen);
    if (!isMobileSidebarOpen) {
      setMobileNavbarOpen(false); // Close navbar if sidebar is opened
    }
  };

  const toggleNavbar = () => {
    setMobileNavbarOpen(!isMobileNavbarOpen);
    if (!isMobileNavbarOpen) {
      setMobileSidebarOpen(false); // Close sidebar if navbar is opened
    }
  };

  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Disable common keyboard shortcuts for opening developer tools
    const handleKeyDown = (e) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
      }
    };

    // Detect if developer tools are open and take action
    const handleDevToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, 'id', {
        get: function () {
          alert('Developer tools are open!');
          window.location.replace('about:blank'); // Redirect to a blank page
        },
      });
      console.log(element);
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    handleDevToolsOpen();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  

 

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => (prevCounter + 1) % slideImages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);


  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);

  const toggleSearchResultsSidebar = () => {
    setSearchResultsSidebarOpen(!isSearchResultsSidebarOpen);
  };


  const userName = localStorage.getItem('name');

  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear local storage
    localStorage.clear();

    // Redirect to the homepage or login page
    navigate('/');
  };





  useEffect(() => {
    // Check local storage for authentication state
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      fetch('  https://backend.clicktalksnow.com/search_bar/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ search_text: query })
      })
        .then(response => response.json())
        .then(data => {

          setSearchResults(data.Names);
          setSearchResultsSidebarOpen(data.Names.length > 0); // Assuming the API directly returns the list of names

        })
        .catch(error => console.error('Error fetching search results:', error));
    } else {
      setSearchResults([]);
      setSearchResultsSidebarOpen(false); // Clear results if the query is cleared
    }
  };



  return (
    <div className='About1'>
      <div className='Home1'>
        <div className="navbar">

          <div className="navbar-logo1" style={{display: isMobile ? 'none' : 'flex' }}>
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link>
          </div>
          <div className='searching'>
            {isAuthenticated && (
              <div className="search-bar">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                  type="text"
                  placeholder="Search"
                  className="search-input"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>)}
          </div>
          <div className="navbar-links">
            {isAuthenticated && (
              <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
            )}
            <Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
            <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

            {isAuthenticated && userName ? (
              <>
                <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                <Link to="/" onClick={handleSignOut}>Sign Out</Link>
              </>
            ) : (
              <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
            )}
          </div>
          <div className="navbar-menu" onClick={toggleNavbar}>
            &#9776; {/* Three dots icon for navbar */}
          </div>
          {isMobileNavbarOpen && (
            <div className="mobile-navbar-links">
              {isAuthenticated && (
                <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
              )}
              <Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
              <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
              <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

              {isAuthenticated && userName ? (
                <>
                  <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                  <Link to="/" onClick={handleSignOut}>Sign Out</Link>
                </>
              ) : (
                <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
              )}

            </div>
          )}
        </div>
      </div>

      <div className='Home-2'>
        <div className="slide-container">
          <div className="slides">
            {slideImages.map((image, index) => (
              <img key={index} src={image} className={index === counter ? 'active' : ''} alt={`Slide ${index}`} />
            ))}
          </div>
        </div>
        <div className="about-content">
          <h2>About Click Talks</h2>
          <p>Click Talks aims to provide a platform for individuals to express their feelings and daily experiences through innovative and creative photography. Our mission is to offer society a unique and accessible way to share their perspectives and stories visually, allowing for a deeper understanding and connection through the art of photography.</p>
        </div>
      </div>

      <div>
        <ContestPage />




        <div className="celebrities-section">
          <h1>Who We Are</h1>
          <h2>What Celebrities Say</h2>
          <div className="image-content-container">
            <div className="image-content">
              <img src={celebrity1} alt="Image 1" />
              <div className="content">
                <p><strong>P.G. Vinda</strong><br />(India Cinematographer & TCA President)<br />P.G. Vinda is considered as one of the leading Indian D.O.P and president of the TCA (Telugu Cinematographer Association). He made his debut as a cinematographer with the National award-winning film Grahanam (2004)… <a href="#">Read More</a></p>
              </div>
            </div>
            <div className="image-content">
              <img src={celebrity2} alt="Image 2" />
              <div className="content">
                <p><strong>K. K. Senthil Kumar</strong><br />(Indian Cinematographer)<br />K. K. Senthil Kumar is an Indian cinematographer who works in Telugu cinema. He is known for his frequent collaborations with S.S. Rajamouli. He went on to work in notable films like Sye (2004)… <a href="#">Read More</a></p>
              </div>
            </div>
            <div className="image-content">
              <img src={celebrity3} alt="Image 3" />
              <div className="content">
                <p><strong>Ajayan Vincent</strong><br />(Indian Cinematographer)<br />Ajayan Vincent is an Indian cinematographer. He is the son of cinematographer and director, A. Vincent. He is known for his work in Malayalam, Tamil, Telugu, and Hindi language films… <a href="#">Read More</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-section">
        <h2>Contact Us</h2>
        <div className="contact-form">
          <form>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile Number:</label>
              <input type="text" id="mobile" name="mobile" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" />
            </div>
            <div className="form-group">
              <label htmlFor="reason">Reason for Enquiry:</label>
              <textarea id="reason" name="reason" rows="4"></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className='blogs'>
        <h2>BLOGS</h2>
        <div className='blogs-container'>
          <div className='blog-item'>
            <img src={blogImg1} alt='Blog 1' />
            <div className='blog-content'>
              <h3>SMARTPHONE FILMMAKING SIMPLIFIED</h3>
              <p>Creating a cinematic masterpiece from your smartphone has never been easier with the help of these DIY filmmaking tips...</p>
            </div>
          </div>
          <div className='blog-item'>
            <img src={blogImg2} alt='Blog 2' />
            <div className='blog-content'>
              <h3>PROFESSIONAL CAMERA VS SMARTPHONE CAMERA!</h3>
              <p>BLOG CONTENT 02: professional camera vs smartphone camera! Difference between professional camera and smartphone camera?...</p>
            </div>
          </div>
          <div className='blog-item'>
            <img src={blogImg3} alt='Blog 3' />
            <div className='blog-content'>
              <h3>TOPIC TIPS TO MAKE A SHORT FILM WITH SMART PHONE!</h3>
              <p>These are important steps to make a short film! Making a short film can be an exciting and rewarding experience...</p>
            </div>
          </div>
        </div>
      </div>

      <MarqueeCarousel />





      <div className='Footer'>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="footer-col">
                <h4>Address</h4>
                <ul>
                  <li><a href="#">Rajbhavan road,</a></li>
                  <li><a href="#">Somajiguda,</a></li>
                  <li><a href="#">Hyderabad - 500082</a></li>
                </ul>
              </div>
              <div className="footer-col">
                <h4>follow us</h4>
                <div className="social-links">
                 <a href="https://www.facebook.com/share/9guFd4Fb3vxLRKAR/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={30} style={{ color: '#4267B2',  }} />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <FaTwitter size={30} style={{ color: '#1DA1F2',  }} />
      </a>
      <a href="https://www.instagram.com/clicktalksfilmclub?igsh=MTNiN3dudjk3a2d0ZQ==" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={30} style={{ color: '#E1306C' }} />
      </a>
                </div>
              </div>
              <div className="footer-col">
                <h4>Contacts</h4>
                <ul>
                  <li><a href="#">+91 7680805167,</a></li>
                  <li><a href="#">clicktalksnow@gmail.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default About;
