import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import back_img from '../../images/Studio photographer-cuate 1 (1).png';
import logo1 from '../../images/footer-logo-2 3.png';
import './membership.css';
import { useNavigate } from 'react-router-dom';
import { Link ,useLocation} from 'react-router-dom';
import benefitsImage from '../../image/Group 113.png';
import mask from '../../image/footer-logo-2 2.svg'


function MembershipForm() {
    const location = useLocation();
    const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);
    const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
    const unique_id = localStorage.getItem('uniqueId');


    useEffect(() => {
        // Check local storage for authentication state
        const authStatus = localStorage.getItem('isAuthenticated');
        if (authStatus != 'true') {
          navigate('/')
        }
    
        // check if membership form is filled or not, if already filled, navigate to profile
        (async () => {
          try {
            const response = await axios.get(`https://backend.clicktalksnow.com/get_is_membership_form_filled/${unique_id}`);
            if (response) {
              if(response.data.is_membership_form_filled){
                navigate('/myprofile')
              }
            }
          } catch (error) {
            console.error('Error:', error);
          }
        })();
      }, []);


    useEffect(() => {
        // Disable right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
    
        // Disable common keyboard shortcuts for opening developer tools
        const handleKeyDown = (e) => {
          if (
            e.key === 'F12' ||
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
            (e.ctrlKey && e.key === 'U')
          ) {
            e.preventDefault();
          }
        };
    
        // Detect if developer tools are open and take action
        const handleDevToolsOpen = () => {
          const element = new Image();
          Object.defineProperty(element, 'id', {
            get: function () {
              alert('Developer tools are open!');
              window.location.replace('about:blank'); // Redirect to a blank page
            },
          });
          console.log(element);
        };
    
        // Add event listeners
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        handleDevToolsOpen();
    
        // Cleanup event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

    const toggleSidebar = () => {
        setMobileSidebarOpen(!isMobileSidebarOpen);
        if (!isMobileSidebarOpen) {
            setMobileNavbarOpen(false); // Close navbar if sidebar is opened
        }
    };

    const toggleNavbar = () => {
        setMobileNavbarOpen(!isMobileNavbarOpen);
        if (!isMobileNavbarOpen) {
            setMobileSidebarOpen(false); // Close sidebar if navbar is opened
        }
    };
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        address: '',
        gender: '',
        college: '',
        interests: [],      // for multiple selections
        referralsource: '',  //How did you hear about us ?
        reasontojoin: '',    //Why do you want to join Click Talks ?
    });
    const [loading, setloading] = useState(false);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
    
        if (name === 'interests') {
            setFormData(prevFormData => {
                if (checked) {
                    // Add to interests if checked
                    return { ...prevFormData, interests: [...prevFormData.interests, value] };
                } else {
                    // Remove from interests if unchecked
                    return { ...prevFormData, interests: prevFormData.interests.filter(interest => interest !== value) };
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const {address, gender, college, interests, referralsource, reasontojoin} = formData;

        if (!address || !gender || !college || interests.length === 0 || !referralsource || !reasontojoin) {
            alert('Please fill out all fields!');
            return;
        }

        try {
            setloading(true);
            const requestData = {address, gender, college, interests, referralsource, reasontojoin};

            const response = await axios.put(`https://backend.clicktalksnow.com/add_membership_details/${unique_id}`, requestData);

            if (response.ok === false) {
                alert('Failed to add membership details');
                return;
            }
            navigate('/myprofile');

        } catch (error) {
            alert('Error in creating membership:', error.message);
        }finally {
            setloading(false);
        }
    };

    return (
        <div className='sid-register'>
            <div className='full-body'>
                <div className="navbar">

                <div className="navbar-logo1">
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>                    <div className="navbar-links">

                 
        <Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
      <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
      <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

      
                    </div>
                    <div className="navbar-menu" onClick={toggleNavbar}>
                        &#9776; {/* Three dots icon for navbar */}
                    </div>
                    {isMobileNavbarOpen && (
                        <div className="mobile-navbar-links">
                             <Link to='/'>Home</Link>

      <Link to="/shortlisted" className={location.pathname === '/shortlisted' ? 'active' : ''}>Shortlisted Participants</Link>
      <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
      <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>
     
                        </div>
                    )}
                </div>
                <div className="container">
                    <div className="form-container">
                        <h2> Click Talks Membership Form</h2>
                        <p>Become a member of ClickTalks and take the first step toward your artistic journey!</p>
                        <p>After filling this form, your <span style={{fontWeight: "bold"}}>e-pass</span> will be visible on the profile page.</p>
                        <form onSubmit={handleSubmit}>
                            <>

                                <label htmlFor="address">Address*</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    placeholder="Enter address"
                                    value={formData.address}
                                    onChange={handleChange}
                                />

                                <label htmlFor="gender">Gender*</label>
                                <select
                                    id="gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    required>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="preferNotToSay">Prefer not to say</option>
                                </select>

                                <label htmlFor="college">College/University Name*</label>
                                <input
                                    type="text"
                                    id="college"
                                    name="college"
                                    placeholder="Enter college/university name"
                                    value={formData.college}
                                    onChange={handleChange}
                                />

                                <label>Areas of Interest*</label>
                                <div>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Filmmaking"
                                            checked={formData.interests.includes("Filmmaking")}
                                            onChange={handleChange}
                                        />
                                        Filmmaking
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Animation"
                                            checked={formData.interests.includes("Animation")}
                                            onChange={handleChange}
                                        />
                                        Animation
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Cinematography"
                                            checked={formData.interests.includes("Cinematography")}
                                            onChange={handleChange}
                                        />
                                        Cinematography
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Photography"
                                            checked={formData.interests.includes("Photography")}
                                            onChange={handleChange}
                                        />
                                        Photography
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Dance"
                                            checked={formData.interests.includes("Dance")}
                                            onChange={handleChange}
                                        />
                                        Dance
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Acting"
                                            checked={formData.interests.includes("Acting")}
                                            onChange={handleChange}
                                        />
                                        Acting
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Visual Artists"
                                            checked={formData.interests.includes("Visual Artists")}
                                            onChange={handleChange}
                                        />
                                        Visual Artists
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Singing"
                                            checked={formData.interests.includes("Singing")}
                                            onChange={handleChange}
                                        />
                                        Singing
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Fashion Aspirant"
                                            checked={formData.interests.includes("Fashion Aspirant")}
                                            onChange={handleChange}
                                        />
                                        Fashion Aspirant
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Gaming"
                                            checked={formData.interests.includes("Gaming")}
                                            onChange={handleChange}
                                        />
                                        Gaming
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Standup Comedy"
                                            checked={formData.interests.includes("Standup Comedy")}
                                            onChange={handleChange}
                                        />
                                        Standup Comedy
                                    </label>
                                    <label style={{fontWeight: "normal", display: "block", marginTop: 5}}>
                                        <input
                                            type="checkbox"
                                            name="interests"
                                            value="Modeling"
                                            checked={formData.interests.includes("Modeling")}
                                            onChange={handleChange}
                                        />
                                        Modeling
                                    </label>
                                </div>

                                <label htmlFor="referralsource">How did you hear about us?*</label>
                                <input
                                    type="text"
                                    id="referralsource"
                                    name="referralsource"
                                    placeholder="E.g., Social Media, Friend, etc."
                                    value={formData.referralSource}
                                    onChange={handleChange}
                                />

                                <label htmlFor="reasontojoin">Why do you want to join Click Talks?*</label>
                                <textarea
                                    id="reasontojoin"
                                    name="reasontojoin"
                                    placeholder="Explain why you want to join"
                                    value={formData.reasonToJoin}
                                    onChange={handleChange}
                                ></textarea>

                                <button disabled={loading}>
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </>

                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default MembershipForm;
